// ---------------------------------------------------------------------
// src/pages/ConversationScenarioSelection/ConversationScenarioSelection.scss
// ---------------------------------------------------------------------

.conversation-scenario-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 20px;

  h2 {
    margin-bottom: 5px;
  }

  .star-count {
    margin: 5px 0 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  .scenarios-list {
    width: 100%;
    max-width: 900px;
  }

  .scenario-group {
    margin-bottom: 20px;

    .group-divider {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &:before,
      &:after {
        content: '';
        flex: 1;
        border-bottom: 2px solid #333;
      }

      &:before {
        margin-right: 8px;
      }

      &:after {
        margin-left: 8px;
      }

      .group-number {
        font-size: 1.2rem;
        font-weight: bold;
        color: #007bff;
        background-color: #f8f9fa;
        padding: 5px 10px;
        border-radius: 10px;
        border: 1px solid #007bff;
      }
    }

    .unlock-message {
      margin-bottom: 15px;
      font-size: 1rem;
      color: #ff0000;
      font-weight: bold;
    }

    .group-scenarios {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 15px;
    }
  }

  .scenario-card {
    position: relative;
    padding: 20px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
    width: 100%;
    /* Bordure plus visible */
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    /* Sur desktop, vous pouvez limiter la largeur ; 
       ici 600px max => ajustez si besoin. */
    max-width: 600px;

    &:hover {
      transform: scale(1.02);
      background-color: #f4f4f4;
    }

    &.selected {
      background-color: #007bff;
      color: white;
      border-color: rgba(0, 0, 0, 0.3);

      /* Si vous avez un .scenario-emoji, 
         vous pouvez le styliser ici. */
      .scenario-emoji {
        color: white;
      }
    }

    &.locked {
      pointer-events: none;

      &:hover {
        transform: none;
        background-color: #ffffff;
      }

      .lock-icon {
        position: absolute;
        top: 11px;
        left: 17px;
        font-size: 2rem;
        color: #ff0000;
      }
    }

    .completed-icon {
      margin-left: 13px;
      font-size: 2rem;
      line-height: 1;
      vertical-align: middle;
    }
  }

  /* Style spécial pour le scénario "free_chat" */
  .scenario-card.free-chat-special {
    background-color: #e0f7fa;
    border: 2px solid #00bcd4;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);

    &:hover {
      transform: scale(1.03);
      background-color: #d0f2f5;
      transition: transform 0.2s ease, background-color 0.2s ease;
    }
  }

  @media (min-width: 1024px) {
    padding: 30px 20px;

    h2 {
      margin-bottom: 8px;
    }

    .star-count {
      font-size: 1.7rem;
      margin: 8px 0 25px;
    }

    .scenario-group .group-divider .group-number {
      font-size: 1.5rem;
      padding: 8px 15px;
    }
  }
}

/* Espace supplémentaire en haut */
.custom-top-padding {
  margin-top: 10px;
}

/* 
   Exemple: si vous voulez (optionnellement) 
   agrandir encore plus sur mobile, vous pouvez laisser 
   un petit forçage. Sinon, supprimez ce bloc. 
*/
@media (max-width: 640px) {
  .conversation-scenario-selection,
  .scenarios-list,
  .scenario-card {
    width: 95% !important;
    max-width: 95% !important;
    margin: 0 auto 1rem auto;
  }

  .scenario-card {
    padding: 16px !important;
  }
}