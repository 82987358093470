/* src/index.css */

/* Importation de Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Styles personnalisés */
html,
body,
#root {
  margin: 0;
  padding: 0;
  
  width: 100%;
  height: 100%;
  
  position: relative;
  
  /* Utilise la police 'Assistant' définie dans index.html (display=swap) */
  font-family: 'Assistant', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  
  color: #18181b;

  /* Antialiasing */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

/*
  Vous pouvez inclure des resets supplémentaires ou 
  des overrides SCSS si besoin (ex. import './App.scss';).
*/